// src/pages/Contact.js

import React from "react";
import { FaArrowLeft, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contact = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Header />
      
      <div className="pt-24">
        <section className="container mx-auto px-4 py-16">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-8 transition-colors duration-200 ease-in-out transform hover:-translate-x-1"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
          <div className="text-center mb-10">
            <h2 className="text-5xl font-extrabold text-green-400 mb-4">Contact Us</h2>
            <p className="text-lg font-light text-gray-300">
              Have questions or need support? Get in touch with our team through any of the methods below.
            </p>
          </div>
          <div className="flex justify-center mb-8">
            <img
              src="/images/contact.png"  // Placeholder image path
              alt="Contact Us"
              className="w-full h-96 object-cover rounded-xl shadow-lg animate-fadeInUp"
            />
          </div>
          <div className="text-lg space-y-8 px-4 md:px-8 lg:px-16">
            <div className="flex items-center space-x-4 animate-fadeInUp">
              <FaEnvelope className="text-green-400 text-2xl" />
              <a href="mailto: Coming Soon..." className="text-gray-300 hover:text-green-400 transition-colors duration-300">
                comingsoon@blyve.cloud
              </a>
            </div>
            <div className="flex items-center space-x-4 animate-fadeInUp">
              <FaPhone className="text-green-400 text-2xl" />
              <a href="tel:Coming Soon..." className="text-gray-300 hover:text-green-400 transition-colors duration-300">
                Coming Soon...
              </a>
            </div>
            <div className="flex items-center space-x-4 animate-fadeInUp">
              <FaMapMarkerAlt className="text-green-400 text-2xl" />
              <span className="text-gray-300">
                Blyve Studio, Long Tuyen Ward, Binh Thuy District, Can Tho City, Vietnam.
              </span>
            </div>
            <div className="mt-12 text-center">
              <h3 className="text-3xl font-semibold text-green-400 mb-4">Follow Us</h3>
              <div className="flex justify-center space-x-6">
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-green-400 transition-colors duration-300"
                  aria-label="Facebook"
                >
                  <FaEnvelope size={24} />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-green-400 transition-colors duration-300"
                  aria-label="Twitter"
                >
                  <FaPhone size={24} />
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-green-400 transition-colors duration-300"
                  aria-label="Instagram"
                >
                  <FaMapMarkerAlt size={24} />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      <Footer />
    </div>
  );
};

export default Contact;
