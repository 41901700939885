// src/pages/JoinServer.js

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";  // Đảm bảo import chính xác
import Footer from "../components/Footer";  // Đảm bảo import chính xác

const JoinServer = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Header />  {/* Thêm Header */}

      <div className="pt-24">
        <section className="container mx-auto px-4 py-16">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-8 transition-colors duration-200 ease-in-out transform hover:-translate-x-1"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
          <div className="text-center mb-10">
            <h2 className="text-5xl font-extrabold text-green-400 mb-4">
              Join Our Minecraft Server
            </h2>
            <p className="text-lg font-light text-gray-300">
              Easy steps to connect and start your adventure with MODS: NO
            </p>
          </div>
          <div className="flex justify-center mb-8">
            <img
              src="/images/join.png"  // Đường dẫn đến hình ảnh
              alt="How to Join"
              className="w-full h-96 object-cover rounded-xl shadow-lg"
            />
          </div>
          <div className="text-lg space-y-12 px-4 md:px-8 lg:px-16">
            <p className="text-gray-200 text-center">
              Welcome to <span className="text-green-400 font-semibold">Blyve</span>! Follow these steps to join our Minecraft server:
            </p>
            <ol className="space-y-12 text-gray-100">
              {/** Các bước gia nhập server Minecraft */}
              <li className="flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold mb-4">Step 1</h3>
                <img src="/images/HD/1.1.png" alt="Step 1" className="w-full md:w-3/4 h-auto rounded-lg shadow-md mb-4" />
                <p>Open Minecraft and go to the Multiplayer section.</p>
              </li>
              <li className="flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold mb-4">Step 2</h3>
                <img src="/images/HD/1.2.png" alt="Step 2" className="w-full md:w-3/4 h-auto rounded-lg shadow-md mb-4" />
                <p>Click on <span className="italic">"Add Server"</span>.</p>
              </li>
              <li className="flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold mb-4">Step 3</h3>
                <img src="/images/HD/1.3.png" alt="Step 3" className="w-full md:w-3/4 h-auto rounded-lg shadow-md mb-4" />
                <p>
                  Enter the server address: <strong className="text-green-400">blyve.cloud:5000</strong>.
                </p>
              </li>
              <li className="flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold mb-4">Step 4</h3>
                <img src="/images/HD/1.4.png" alt="Step 4" className="w-full md:w-3/4 h-auto rounded-lg shadow-md mb-4" />
                <p>Click <span className="italic">"Done"</span> and then select the server to join.</p>
              </li>
            </ol>
            <p className="text-gray-300 text-center mt-6">
              If you encounter any issues, feel free to join our Discord server for support.
            </p>
          </div>
        </section>
      </div>

      <Footer /> {/* Thêm Footer */}
    </div>
  );
};

export default JoinServer;
