// src/components/Header.js

import React, { useState, useEffect, useRef } from "react";
import {
  FaFacebookF,
  FaBars,
  FaTimes,
  FaUserFriends,
  FaClipboardList,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isFacebookModalOpen, setIsFacebookModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleOpenFacebookModal = () => {
    setIsFacebookModalOpen(true);
  };

  const handleCloseFacebookModal = () => {
    setIsFacebookModalOpen(false);
  };

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsFacebookModalOpen(false);
      }
    };

    if (isFacebookModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFacebookModalOpen]);

  return (
    <header className="bg-gray-800 text-gray-200 fixed top-0 left-0 w-full z-50 shadow-md">
      <div className="container mx-auto flex items-center justify-between px-4 py-4">
        {/* Logo and Title */}
        <div className="flex items-center space-x-3">
          <Link to="/" onClick={handleCloseMobileMenu}>
            <img
              src="/images/blyve-logo.png" // Ensure the logo path is correct
              alt="Blyve Logo"
              className="w-12 h-12 rounded-full object-cover"
            />
          </Link>
          <Link to="/" onClick={handleCloseMobileMenu}>
            <h1 className="text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
              Blyve
            </h1>
          </Link>
        </div>

        {/* Navigation Links (Hidden on Mobile) */}
        <nav className="hidden md:flex space-x-6">
          <NavLink
            exact
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-green-400"
                : "hover:text-green-400 transition-colors duration-300"
            }
            onClick={handleCloseMobileMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "text-green-400"
                : "hover:text-green-400 transition-colors duration-300"
            }
            onClick={handleCloseMobileMenu}
          >
            About Us
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive
                ? "text-green-400"
                : "hover:text-green-400 transition-colors duration-300"
            }
            onClick={handleCloseMobileMenu}
          >
            Services
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive
                ? "text-green-400"
                : "hover:text-green-400 transition-colors duration-300"
            }
            onClick={handleCloseMobileMenu}
          >
            Contact
          </NavLink>
          <NavLink
            to="/terms-and-conditions"
            className={({ isActive }) =>
              isActive
                ? "text-green-400"
                : "hover:text-green-400 transition-colors duration-300"
            }
            onClick={handleCloseMobileMenu}
          >
            Terms & Conditions
          </NavLink>
        </nav>

        {/* Facebook Modal Button (Hidden on Mobile) */}
        <div className="hidden md:flex relative">
          <button
            onClick={handleOpenFacebookModal}
            className="flex items-center bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 transition-colors duration-300 px-4 py-2 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-haspopup="dialog"
            aria-expanded={isFacebookModalOpen}
            aria-label="Open Facebook Options"
          >
            <FaFacebookF className="mr-2 text-xl" />
            <span className="font-medium">Facebook</span>
            <svg
              className={`ml-2 w-4 h-4 transition-transform duration-300 ${
                isFacebookModalOpen ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>

          {/* Modal Overlay */}
          {isFacebookModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-60">
              <div
                ref={modalRef}
                className="bg-gray-700 rounded-md shadow-lg w-80 p-6 relative"
                role="dialog"
                aria-modal="true"
                aria-labelledby="facebook-modal-title"
              >
                <h2
                  id="facebook-modal-title"
                  className="text-xl font-semibold mb-4 text-white"
                >
                  Facebook Options
                </h2>
                <button
                  onClick={handleCloseFacebookModal}
                  className="absolute top-3 right-3 text-gray-300 hover:text-white focus:outline-none"
                  aria-label="Close Modal"
                >
                  <FaTimes className="text-2xl" />
                </button>
                <div className="flex flex-col space-y-4">
                  <a
                    href="https://www.facebook.com/profile.php?id=61569092676699"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center px-4 py-2 bg-gray-600 rounded hover:bg-gray-500 transition-colors duration-300"
                  >
                    <FaUserFriends className="mr-3 text-lg" />
                    Fanpage
                  </a>
                  <a
                    href="https://www.facebook.com/groups/872406048431044"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center px-4 py-2 bg-gray-600 rounded hover:bg-gray-500 transition-colors duration-300"
                  >
                    <FaClipboardList className="mr-3 text-lg" />
                    Group
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            onClick={handleToggleMobileMenu}
            className="text-2xl focus:outline-none"
            aria-label="Toggle Mobile Menu"
          >
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-gray-800 text-gray-200 px-4 pb-4 animate-slideDown">
          <nav className="flex flex-col space-y-4 mt-2">
            <NavLink
              exact
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-green-400"
                  : "hover:text-green-400 transition-colors duration-300"
              }
              onClick={handleCloseMobileMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "text-green-400"
                  : "hover:text-green-400 transition-colors duration-300"
              }
              onClick={handleCloseMobileMenu}
            >
              About Us
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                isActive
                  ? "text-green-400"
                  : "hover:text-green-400 transition-colors duration-300"
              }
              onClick={handleCloseMobileMenu}
            >
              Services
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "text-green-400"
                  : "hover:text-green-400 transition-colors duration-300"
              }
              onClick={handleCloseMobileMenu}
            >
              Contact
            </NavLink>
            <NavLink
              to="/terms-and-conditions"
              className={({ isActive }) =>
                isActive
                  ? "text-green-400"
                  : "hover:text-green-400 transition-colors duration-300"
              }
              onClick={handleCloseMobileMenu}
            >
              Terms & Conditions
            </NavLink>
            {/* Facebook Modal Button for Mobile */}
            <div className="relative">
              <button
                onClick={handleOpenFacebookModal}
                className="flex items-center bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 transition-colors duration-300 px-4 py-2 rounded-full shadow-lg w-full text-left focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-haspopup="dialog"
                aria-expanded={isFacebookModalOpen}
                aria-label="Open Facebook Options"
              >
                <FaFacebookF className="mr-2 text-xl" />
                <span className="font-medium">Facebook</span>
                <svg
                  className={`ml-2 w-4 h-4 transition-transform duration-300 ${
                    isFacebookModalOpen ? "transform rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {/* Modal Overlay for Mobile */}
              {isFacebookModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-60">
                  <div
                    ref={modalRef}
                    className="bg-gray-700 rounded-md shadow-lg w-80 p-6 relative"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="facebook-modal-title-mobile"
                  >
                    <h2
                      id="facebook-modal-title-mobile"
                      className="text-xl font-semibold mb-4 text-white"
                    >
                      Facebook Options
                    </h2>
                    <button
                      onClick={handleCloseFacebookModal}
                      className="absolute top-3 right-3 text-gray-300 hover:text-white focus:outline-none"
                      aria-label="Close Modal"
                    >
                      <FaTimes className="text-2xl" />
                    </button>
                    <div className="flex flex-col space-y-4">
                      <a
                        href="https://www.facebook.com/profile.php?id=61569092676699"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center px-4 py-2 bg-gray-600 rounded hover:bg-gray-500 transition-colors duration-300"
                      >
                        <FaUserFriends className="mr-3 text-lg" />
                        Fanpage
                      </a>
                      <a
                        href="https://www.facebook.com/groups/872406048431044"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center px-4 py-2 bg-gray-600 rounded hover:bg-gray-500 transition-colors duration-300"
                      >
                        <FaClipboardList className="mr-3 text-lg" />
                        Group
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
