// src/pages/Home.js

import React, { useState, useEffect } from "react";
import {
  FaCopy,
  FaServer,
  FaArrowRight,
  FaDownload,
  FaGamepad,
  FaCodeBranch,
  FaCube,
  FaCogs,
  FaCircle,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const HomePage = () => {
  // State variables
  const [isCopied, setIsCopied] = useState(false);
  const [serverStatus, setServerStatus] = useState("Loading...");
  const [onlinePlayers, setOnlinePlayers] = useState(0);
  const [maxPlayers, setMaxPlayers] = useState(0);
  const [gameMode, setGameMode] = useState("");
  const [gameVersion, setGameVersion] = useState("");
  const [minecraftEdition, setMinecraftEdition] = useState("");
  const [modsEnabled, setModsEnabled] = useState("");

  const serverIP = "blyve.cloud";
  const serverPort = "5000";
  const navigate = useNavigate();

  // Fetch server status data
  const fetchServerStatus = async () => {
    try {
      const response = await fetch("/status");
      const data = await response.json();
      setServerStatus(data.status);
      setOnlinePlayers(data.onlinePlayers || 0);
      setMaxPlayers(data.maxPlayers || 0);
      setGameMode(data.gameMode);
      setGameVersion(data.gameVersion);
      setMinecraftEdition(data.minecraftEdition);
      setModsEnabled(data.modsEnabled);
    } catch (error) {
      console.error("Failed to fetch server status:", error);
      setServerStatus("Offline");
    }
  };

  // Fetch data on component mount and set interval for updates
  useEffect(() => {
    fetchServerStatus();
    const intervalId = setInterval(fetchServerStatus, 5000);
    return () => clearInterval(intervalId);
  }, []);

  // Copy server address to clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`${serverIP}:${serverPort}`);
      setIsCopied(true);
      toast.success("Server address copied!", {
        position: "bottom-right",
        autoClose: 3000,
      });
      setTimeout(() => setIsCopied(false), 3000);
    } catch (err) {
      toast.error("Failed to copy address");
    }
  };

  // Get color based on server status
  const getStatusColor = () => {
    switch (serverStatus) {
      case "Online":
        return "text-green-500";
      case "Busy":
        return "text-red-500";
      case "Offline":
      default:
        return "text-gray-500";
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 overflow-x-hidden">
      <ToastContainer />
      <Header />

      <div className="pt-24">
        {/* Welcome Section */}
        <section className="container mx-auto px-4 py-16 text-center animate-fadeInUp">
          <h2 className="text-5xl font-extrabold mb-8 text-gradient bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 bg-clip-text text-transparent">
            Welcome to Blyve
          </h2>

          {/* Server Information Card */}
          <div className="max-w-4xl mx-auto bg-gradient-to-br from-gray-800 via-gray-900 to-black p-8 rounded-xl shadow-2xl border border-green-400 transition-transform duration-500 ease-in-out hover:-translate-y-2 hover:shadow-2xl hover:shadow-green-400/50 animate-fadeIn backface-hidden">
            <h3 className="text-3xl font-bold mb-6 text-green-400 animate-pulse">
              Server Information
            </h3>

            {/* Server Address */}
            <div className="mb-8">
              <p className="text-xl mb-2">Server Address:</p>
              <div className="flex flex-col items-center space-y-4">
                <span className="text-2xl font-mono text-green-400 animate-pulse">
                  {serverIP}:{serverPort}
                </span>
                <div className="flex space-x-4">
                  <button
                    onClick={handleCopy}
                    className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
                      isCopied
                        ? "bg-green-600"
                        : "bg-gray-700 hover:bg-gray-600"
                    } transition-colors duration-300 transform hover:scale-105`}
                    aria-label="Copy server address"
                  >
                    <FaCopy className="animate-bounce-slow" />
                    <span>{isCopied ? "Copied!" : "Copy"}</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Server Details */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mt-4">
              {/* Server Status */}
              <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center shadow-md hover:shadow-green-400/50 transition-shadow duration-500 ease-in-out transform hover:-translate-y-1">
                <FaCircle
                  className={`${getStatusColor()} mb-2 text-3xl animate-spin-slow`}
                />
                <div className="text-center">
                  <p className="text-lg mb-1">Status</p>
                  <p className="text-xl font-mono">{serverStatus}</p>
                </div>
              </div>

              {/* Players */}
              <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center shadow-md hover:shadow-green-400/50 transition-shadow duration-500 ease-in-out transform hover:-translate-y-1">
                <FaServer className="text-green-400 mb-2 text-3xl animate-pulse" />
                <div className="text-center">
                  <p className="text-lg mb-1">Players</p>
                  <p className="text-xl font-mono">
                    {onlinePlayers}/{maxPlayers}
                  </p>
                </div>
              </div>

              {/* Game Mode */}
              <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center shadow-md hover:shadow-green-400/50 transition-shadow duration-500 ease-in-out transform hover:-translate-y-1">
                <FaGamepad className="text-green-400 mb-2 text-3xl animate-bounce" />
                <div className="text-center">
                  <p className="text-lg mb-1">Game Mode</p>
                  <p className="text-xl font-mono">{gameMode}</p>
                </div>
              </div>

              {/* Game Version */}
              <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center shadow-md hover:shadow-green-400/50 transition-shadow duration-500 ease-in-out transform hover:-translate-y-1">
                <FaCodeBranch className="text-green-400 mb-2 text-3xl animate-pulse" />
                <div className="text-center">
                  <p className="text-lg mb-1">Game Version</p>
                  <p className="text-xl font-mono">{gameVersion}</p>
                </div>
              </div>

              {/* Minecraft Edition */}
              <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center shadow-md hover:shadow-green-400/50 transition-shadow duration-500 ease-in-out transform hover:-translate-y-1">
                <FaCube className="text-green-400 mb-2 text-3xl animate-spin-slow" />
                <div className="text-center">
                  <p className="text-lg mb-1">Minecraft Edition</p>
                  <p className="text-xl font-mono">{minecraftEdition}</p>
                </div>
              </div>

              {/* Mods */}
              <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center shadow-md hover:shadow-green-400/50 transition-shadow duration-500 ease-in-out transform hover:-translate-y-1">
                <FaCogs className="text-green-400 mb-2 text-3xl animate-spin-slow" />
                <div className="text-center">
                  <p className="text-lg mb-1">Mods</p>
                  <p className="text-xl font-mono">{modsEnabled}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Download Prompt */}
          <div className="mt-8 animate-fadeInUp delay-200">
            <p className="text-lg text-gray-400">
              You haven't installed Minecraft yet? Please download it{" "}
              <a
                href="https://www.minecraft.net/en-us"
                className="text-green-400 underline hover:text-green-300 transition-colors duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </section>

        {/* Server Guides */}
        <section className="container mx-auto px-4 py-16 animate-fadeInUp delay-400">
          <h2 className="text-4xl font-bold mb-12 text-center text-gradient bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 bg-clip-text text-transparent">
            Server Guides
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                title: "How to Join Server without MODS",
                description:
                  "Follow these simple steps to connect to our Minecraft server and start your adventure!",
                icon: <FaServer />,
                image: "/images/join.png",
                link: "/join-server",
              },
              {
                title: "How to Join Server with MODS",
                description:
                  "Learn how to enhance your gameplay experience with our carefully curated mod collection.",
                icon: <FaDownload />,
                image: "/images/install.png",
                link: "/install-mods",
              },
            ].map((guide, index) => (
              <div
                key={index}
                className="bg-gray-800 rounded-xl overflow-hidden shadow-2xl transition-all duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-green-400/40"
              >
                <img
                  src={guide.image}
                  alt={guide.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <span className="text-green-400 text-2xl animate-bounce-slow">
                      {guide.icon}
                    </span>
                    <h3 className="text-2xl font-bold">{guide.title}</h3>
                  </div>
                  <p className="text-gray-400 mb-4">{guide.description}</p>
                  <button
                    onClick={() => navigate(guide.link)}
                    className="flex items-center space-x-2 text-green-400 hover:text-green-300 transition-colors duration-300"
                  >
                    <span>Read More</span>
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
