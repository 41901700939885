// src/pages/InstallMods.js

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const InstallMods = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Header />
      
      <div className="pt-24">
        <section className="container mx-auto px-4 py-16">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-8 transition-colors duration-200 ease-in-out transform hover:-translate-x-1"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
          <div className="text-center mb-10">
            <h2 className="text-5xl font-extrabold text-green-400 mb-4">Install Mods</h2>
            <p className="text-lg font-light text-gray-300">
              Enhance your Minecraft experience with these custom mods. Follow the instructions below to get started.
            </p>
          </div>
          <div className="flex justify-center mb-8">
            <img
              src="/images/install.png"
              alt="Installing Mods"
              className="w-full h-96 object-cover rounded-xl shadow-lg"
            />
          </div>
          <div className="text-lg space-y-12 px-4 md:px-8 lg:px-16 text-center">
            <p className="mt-12">
              <a
                href="https://drive.google.com/drive/folders/15YDc3Kbl1AYbu8wAdy9dT5rKdEqBiRiV"
                className="inline-block px-8 py-3 bg-green-400 text-white text-2xl font-semibold rounded-lg shadow-lg hover:bg-green-500 transition-all duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Mods
              </a>
            </p>
            <p className="text-gray-300 mt-6">
              Click the button above to download the mods and customize your Minecraft experience.
            </p>

            <p className="mt-12">
              <a
                href="https://video.blyve"
                className="inline-block px-8 py-3 bg-blue-400 text-white text-2xl font-semibold rounded-lg shadow-lg hover:bg-blue-500 transition-all duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                Watch Installation Guide (Video)
              </a>
            </p>
            <p className="text-gray-300 mt-6">
              Need help? Click the button above to watch the video tutorial.
            </p>
          </div>
        </section>
      </div>
      
      <Footer />
    </div>
  );
};

export default InstallMods;
