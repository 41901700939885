// src/pages/Services.js

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Services = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Header />
      
      <div className="pt-24">
        <section className="container mx-auto px-4 py-16">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-8 transition-colors duration-200 ease-in-out transform hover:-translate-x-1"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
          <div className="text-center mb-10">
            <h2 className="text-5xl font-extrabold text-green-400 mb-4">Our Services</h2>
            <p className="text-lg font-light text-gray-300">
              At Blyve Minecraft Server, we offer a range of services designed to enhance your gaming experience. From custom plugins to dedicated support, we ensure that our server meets the highest standards of quality and performance.
            </p>
          </div>
          <div className="flex justify-center mb-8">
            <img
              src="/images/services.png"  // Placeholder image path
              alt="Our Services"
              className="w-full h-96 object-cover rounded-xl shadow-lg animate-fadeInUp"
            />
          </div>
          <div className="text-lg space-y-8 px-4 md:px-8 lg:px-16 text-center">
            <div className="animate-fadeInUp">
              <h3 className="text-3xl font-semibold text-green-400 mb-4">Custom Plugins</h3>
              <p className="text-gray-300">
                We develop and integrate custom plugins tailored to your gameplay preferences, ensuring a unique and engaging experience for all players.
              </p>
            </div>
            <div className="animate-fadeInUp">
              <h3 className="text-3xl font-semibold text-green-400 mb-4">24/7 Support</h3>
              <p className="text-gray-300">
                Our dedicated support team is available around the clock to assist you with any issues or questions you may have, ensuring a smooth and enjoyable gaming experience.
              </p>
            </div>
            <div className="animate-fadeInUp">
              <h3 className="text-3xl font-semibold text-green-400 mb-4">Regular Updates</h3>
              <p className="text-gray-300">
                We provide regular updates and maintenance to keep the server running optimally, introducing new features and content to keep the gameplay fresh and exciting.
              </p>
            </div>
          </div>
        </section>
      </div>
      
      <Footer />
    </div>
  );
};

export default Services;
